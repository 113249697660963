import React from "react"
import { FaTimes } from 'react-icons/fa';
import { main } from "./Dialog.module.css";

export default ({ message, onClose }) => {
    return (
        <div className={main}>
            <div>
                <span
                    role="button"
                    tabIndex="0"
                    onClick={onClose}
                    onKeyPress={onClose}
                >
                    <FaTimes size={'1.5em'} />
                </span>
            </div>
            <div>
                <h4>{message}</h4>
            </div>
        </div>
    );
}
