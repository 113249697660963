import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa";
import isEmail from "validator/es/lib/isEmail";
import isEmpty from "validator/es/lib/isEmpty";
import { main } from "./get-a-quote.module.css";
import { internalContainer, rotate } from "../styles/utils.module.css";
import Layout from "../components/Layout";
import InternalSection from "../components/InternalSection";
import InternalSectionTitle from "../components/InternalSectionTitle";
import InternalForm from "../components/InternalForm";
import Button from "../components/Button";
import InternalSidebar from "../components/InternalSidebar";
import Dialog from "../components/Dialog";
import useGlobal from "../hooks/useGlobal";

export default () => {
    const { t } = useTranslation();

    const copy = t(`getAQuote`, { returnObjects: true });

    copy.common = t(`common`, { returnObjects: true });

    const initialClientDetails = {
        firstName: "",
        emailAddress: "",
    };

    const [clientDetails, setClientDetails] = useGlobal("clientDetails", initialClientDetails);

    const initialFormFieldValues = {
        firstName: {
            value: clientDetails.firstName || "",
            error: null,
        },
        lastName: {
            value: "",
            error: null,
        },
        emailAddress: {
            value: clientDetails.emailAddress || "",
            error: null,
        },
        areaCode: {
            value: "",
            error: null,
        },
        phoneNumber: {
            value: "",
            error: null,
        },
        pickUpLocation: {
            value: {
                buildingAccess: copy.common.form.sections.location.fields.buildingAccess.options[0]
            },
            error: null,
        },
        dropOffLocation: {
            value: {
                buildingAccess: copy.common.form.sections.location.fields.buildingAccess.options[0]
            },
            error: null,
        },
        date: {
            value: "",
            error: null,
        },
        time: {
            value: "",
            error: null,
        },
        movingItems: {
            value: {},
            error: null,
        },
    };

    const [state, setState] = useState({
        form: {
            fields: initialFormFieldValues,
            result: null,
            isBeingProcessed: false,
        },
    });

    const validate = (fieldName, value) => {
        if (fieldName === "firstName") {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.name.fields.firstName.errors.empty,
                };
            }
        }

        if (fieldName === "areaCode") {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.phoneNumber.fields.areaCode.errors.empty,
                };
            }
        }

        if (fieldName === "phoneNumber") {
            if (isEmpty(value, { ignore_whitespace: true })) {
                return {
                    value,
                    error: copy.form.sections.phoneNumber.fields.phoneNumber.errors.empty,
                };
            }
        }

        if (fieldName === "emailAddress") {
            if (!isEmail(value)) {
                return {
                    value,
                    error: copy.form.sections.email.fields.emailAddress.errors.invalidFormat,
                };
            }
        }

        return {
            value,
            error: null,
        };
    };

    const handleChange = (fieldName, value) => {
        setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                fields: {
                    ...prevState.form.fields,
                    [fieldName]: {
                        ...prevState.form.fields[fieldName],
                        value,
                    },
                },
            },
        }));
    };

    const getAQuote = async event => {
        event.preventDefault();

        const newState = {
            form: { fields: {}, result: null, isBeingProcessed: false },
        };

        let hasErrors = false;

        for (const fieldName in state.form.fields) {
            const validationResult = validate(fieldName, state.form.fields[fieldName].value);

            if (validationResult.error !== null) {
                hasErrors = true;
            }

            newState.form.fields[fieldName] = validationResult;
        }

        if (!hasErrors) {
            setState(prevState => ({
                form: { ...prevState.form, isBeingProcessed: true },
            }));
            try {
                const response = await sendForm({
                    firstName: state.form.fields.firstName.value,
                    lastName: state.form.fields.lastName.value,
                    emailAddress: state.form.fields.emailAddress.value,
                    areaCode: state.form.fields.areaCode.value,
                    phoneNumber: state.form.fields.phoneNumber.value,
                    pickUpLocation: state.form.fields.pickUpLocation.value,
                    dropOffLocation: state.form.fields.dropOffLocation.value,
                    date: state.form.fields.date.value,
                    time: state.form.fields.time.value,
                    movingItems: Object.keys(state.form.fields.movingItems.value).map(categoryId => ({
                        name: state.form.fields.movingItems.value[categoryId].name,
                        items: Object.keys(state.form.fields.movingItems.value[categoryId].items).map(itemId => ({
                            name: state.form.fields.movingItems.value[categoryId].items[itemId].name,
                            count: state.form.fields.movingItems.value[categoryId].items[itemId].count,
                        })),
                    })),
                });

                if (response.status === 200) {
                    newState.form.result = copy.form.result.success;
                    newState.form.fields = initialFormFieldValues;
                } else {
                    throw await response.json();
                }
            } catch (error) {
                newState.form.result = copy.form.result.error;
            }
            setClientDetails(initialClientDetails);
        }

        setState(newState);
    };

    const sendForm = data => {
        const url = `${process.env.GATSBY_API_URL}/v1/quote`;
        return fetch(url, { method: "POST", body: JSON.stringify(data) });
    };

    const closeDialog = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
        setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                result: null,
            },
        }));
    };

    return (
        <Layout>
            <InternalSection content={copy}>
                <div className={`${main} ${internalContainer}`}>
                    <div>
                        <InternalSectionTitle>{copy.mainContent.title}</InternalSectionTitle>
                        <p>{copy.mainContent.paragraphs[0]}</p>
                        <InternalForm>
                            <div data-section>
                                <h4>{copy.form.sections.name.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.name.fields.firstName.label}
                                            id="first-name"
                                            type="text"
                                            value={state.form.fields.firstName.value}
                                            onChange={event => handleChange("firstName", event.target.value)}
                                        />
                                        {state.form.fields.firstName.error && <small data-error>{state.form.fields.firstName.error}</small>}
                                        <label htmlFor="first-name">{copy.form.sections.name.fields.firstName.label}</label>
                                    </div>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.name.fields.lastName.label}
                                            id="last-name"
                                            type="text"
                                            value={state.form.fields.lastName.value}
                                            onChange={event => handleChange("lastName", event.target.value)}
                                        />
                                        {state.form.fields.lastName.error && <small data-error>{state.form.fields.lastName.error}</small>}
                                        <label htmlFor="last-name">{copy.form.sections.name.fields.lastName.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.email.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.email.fields.emailAddress.label}
                                            id="email-address"
                                            type="email"
                                            value={state.form.fields.emailAddress.value}
                                            onChange={event => handleChange("emailAddress", event.target.value)}
                                        />
                                        {state.form.fields.emailAddress.error && <small data-error>{state.form.fields.emailAddress.error}</small>}
                                        <label htmlFor="email-address">{copy.form.sections.email.fields.emailAddress.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.phoneNumber.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.phoneNumber.fields.areaCode.label}
                                            id="area-code"
                                            type="tel"
                                            value={state.form.fields.areaCode.value}
                                            onChange={event => handleChange("areaCode", event.target.value)}
                                        />
                                        {state.form.fields.areaCode.error && <small data-error>{state.form.fields.areaCode.error}</small>}
                                        <label htmlFor="area-code">{copy.form.sections.phoneNumber.fields.areaCode.label}</label>
                                    </div>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.phoneNumber.fields.phoneNumber.label}
                                            id="phone-number"
                                            type="tel"
                                            value={state.form.fields.phoneNumber.value}
                                            onChange={event => handleChange("phoneNumber", event.target.value)}
                                        />
                                        {state.form.fields.phoneNumber.error && <small data-error>{state.form.fields.phoneNumber.error}</small>}
                                        <label htmlFor="phone-number">{copy.form.sections.phoneNumber.fields.phoneNumber.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.dateAndTime.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.dateAndTime.fields.date.label}
                                            id="date"
                                            type="date"
                                            value={state.form.fields.date.value}
                                            onChange={event => handleChange("date", event.target.value)}
                                        />
                                        {state.form.fields.date.error && <small data-error>{state.form.fields.date.error}</small>}
                                        <label htmlFor="date">{copy.form.sections.dateAndTime.fields.date.label}</label>
                                    </div>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.dateAndTime.fields.time.label}
                                            id="time"
                                            type="time"
                                            value={state.form.fields.time.value}
                                            onChange={event => handleChange("time", event.target.value)}
                                        />
                                        {state.form.fields.time.error && <small data-error>{state.form.fields.time.error}</small>}
                                        <label htmlFor="time">{copy.form.sections.dateAndTime.fields.time.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.pickUpLocation.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.pickUpLocation.fields.address.label}
                                            id="pick-up-location-address"
                                            type="text"
                                            value={state.form.fields.pickUpLocation.value.address || ""}
                                            onChange={event => {
                                                handleChange("pickUpLocation", {
                                                    ...state.form.fields.pickUpLocation.value,
                                                    address: event.target.value,
                                                });
                                            }}
                                        />
                                        {(state.form.fields.pickUpLocation.error || {}).address && (
                                            <small data-error>{(state.form.fields.pickUpLocation.error || {}).address}</small>
                                        )}
                                        <label htmlFor="pick-up-location-address">{copy.form.sections.pickUpLocation.fields.address.label}</label>
                                    </div>
                                    <div data-column>
                                        <select
                                            aria-label={copy.common.form.sections.location.fields.buildingAccess.label}
                                            id="pick-up-location-building-access"
                                            value={state.form.fields.pickUpLocation.value.buildingAccess}
                                            onChange={event => {
                                                handleChange("pickUpLocation", {
                                                    ...state.form.fields.pickUpLocation.value,
                                                    buildingAccess: event.target.value,
                                                });
                                            }}
                                        >
                                            {copy.common.form.sections.location.fields.buildingAccess.options.map((opt, index) => (
                                                <option key={`pick-up-location-building-access-option-${index}`} value={opt.label}>
                                                    {opt.label}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="pick-up-location-building-access">
                                            {copy.common.form.sections.location.fields.buildingAccess.label}
                                        </label>
                                    </div>
                                </div>
                                <div data-row>
                                    <div data-column>
                                        <textarea
                                            aria-label={copy.form.sections.pickUpLocation.fields.importantObservations.label}
                                            id="pick-up-location-important-observations"
                                            placeholder={copy.form.sections.pickUpLocation.fields.importantObservations.placeholder}
                                            value={state.form.fields.pickUpLocation.value.importantObservations || ""}
                                            onChange={event => {
                                                handleChange("pickUpLocation", {
                                                    ...state.form.fields.pickUpLocation.value,
                                                    importantObservations: event.target.value,
                                                });
                                            }}
                                        />
                                        {(state.form.fields.pickUpLocation.error || {}).importantObservations && (
                                            <small data-error>{(state.form.fields.pickUpLocation.error || {}).importantObservations}</small>
                                        )}
                                        <label htmlFor="pick-up-location-access">{copy.form.sections.pickUpLocation.fields.importantObservations.label}</label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.form.sections.dropOffLocation.title}</h4>
                                <div data-row>
                                    <div data-column>
                                        <input
                                            aria-label={copy.form.sections.dropOffLocation.fields.address.label}
                                            id="drop-off-location-address"
                                            type="text"
                                            value={state.form.fields.dropOffLocation.value.address || ""}
                                            onChange={event => {
                                                handleChange("dropOffLocation", {
                                                    ...state.form.fields.dropOffLocation.value,
                                                    address: event.target.value,
                                                });
                                            }}
                                        />
                                        {(state.form.fields.dropOffLocation.error || {}).address && (
                                            <small data-error>{(state.form.fields.dropOffLocation.error || {}).address}</small>
                                        )}
                                        <label htmlFor="drop-off-location-address">{copy.form.sections.dropOffLocation.fields.address.label}</label>
                                    </div>
                                    <div data-column>
                                        <select
                                            aria-label={copy.common.form.sections.location.fields.buildingAccess.label}
                                            id="drop-off-location-building-access"
                                            value={state.form.fields.dropOffLocation.value.buildingAccess || ""}
                                            onChange={event => {
                                                handleChange("dropOffLocation", {
                                                    ...state.form.fields.dropOffLocation.value,
                                                    buildingAccess: event.target.value,
                                                });
                                            }}
                                        >
                                            {copy.common.form.sections.location.fields.buildingAccess.options.map((opt, index) => (
                                                <option key={`drop-off-location-building-access-option-${index}`} value={opt.label}>
                                                    {opt.label}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="drop-off-location-building-access">
                                            {copy.common.form.sections.location.fields.buildingAccess.label}
                                        </label>
                                    </div>
                                </div>
                                <div data-row>
                                    <div data-column>
                                        <textarea
                                            aria-label={copy.form.sections.dropOffLocation.fields.importantObservations.label}
                                            id="drop-off-location-important-observations"
                                            placeholder={copy.form.sections.dropOffLocation.fields.importantObservations.placeholder}
                                            value={state.form.fields.dropOffLocation.value.importantObservations || ""}
                                            onChange={event => {
                                                handleChange("dropOffLocation", {
                                                    ...state.form.fields.dropOffLocation.value,
                                                    importantObservations: event.target.value,
                                                });
                                            }}
                                        />
                                        {(state.form.fields.dropOffLocation.error || {}).importantObservations && (
                                            <small data-error>{(state.form.fields.dropOffLocation.error || {}).importantObservations}</small>
                                        )}
                                        <label htmlFor="drop-off-location-access">
                                            {copy.form.sections.dropOffLocation.fields.importantObservations.label}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div data-section>
                                <h4>{copy.common.form.sections.movingItems.title}</h4>
                                {copy.common.form.sections.movingItems.categories.map(category => {
                                    const categoryId = category.name.toLowerCase().replace(/\s+/gi, "_");
                                    return (
                                        <div data-row key={`moving-items-${categoryId}`}>
                                            <div data-column>
                                                <fieldset>
                                                    <legend>{category.name}</legend>
                                                    {category.items.map(item => {
                                                        const itemId = item.label.toLowerCase().replace(/\s+/gi, "_");
                                                        const uniqueItemId = `${categoryId}-${itemId}`;
                                                        return (
                                                            <div key={`moving-items-${uniqueItemId}`}>
                                                                <input
                                                                    aria-label={item.label}
                                                                    id={uniqueItemId}
                                                                    placeholder="0"
                                                                    type="number"
                                                                    value={
                                                                        ((state.form.fields.movingItems.value[categoryId] || { items: {} }).items[itemId] || {})
                                                                            .count || ""
                                                                    }
                                                                    onChange={event => {
                                                                        const count = parseInt(event.target.value);
                                                                        if (count > 0) {
                                                                            if (!state.form.fields.movingItems.value[categoryId]) {
                                                                                state.form.fields.movingItems.value[categoryId] = {
                                                                                    name: category.name,
                                                                                    items: {},
                                                                                };
                                                                            }
                                                                            state.form.fields.movingItems.value[categoryId].items[itemId] = {
                                                                                name: item.label,
                                                                                count,
                                                                            };
                                                                        } else {
                                                                            if (
                                                                                state.form.fields.movingItems.value[categoryId] &&
                                                                                state.form.fields.movingItems.value[categoryId].items[itemId]
                                                                            ) {
                                                                                delete state.form.fields.movingItems.value[categoryId].items[itemId];
                                                                            }
                                                                        }
                                                                        handleChange("movingItems", state.form.fields.movingItems.value);
                                                                    }}
                                                                />
                                                                <br />
                                                                <label htmlFor={uniqueItemId}>{item.label}</label>
                                                            </div>
                                                        );
                                                    })}
                                                </fieldset>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <Button onClick={async event => await getAQuote(event)} onKeyPress={async event => await getAQuote(event)}>
                                {state.form.isBeingProcessed ? <FaSpinner className={rotate} /> : copy.form.submitButton.label}
                            </Button>
                        </InternalForm>
                    </div>
                    <div>
                        <InternalSidebar quotation={false} />
                    </div>
                </div>
            </InternalSection>
            {state.form.result !== null && <Dialog message={state.form.result} onClose={closeDialog} />}
        </Layout>
    );
};
